<template>
    <section class="legal-notice-container">
        <h1>Mentions légales</h1>

        <div class="legal-notice-text-container">
            <div class="text-wrapper">
                <p class="subtitle">Propriétaire</p>
                <p>SAS COLOR THE EDUCATION</p>
                <p>3 rue Beaubadat</p>
                <p>33000 Bordeaux</p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">Gestion des données personnelles</p>
                <p>
                    Les données personnelles collectées (nom, prénom,
                    profession, ville, adresse mail) ne sont collectées que dans
                    le but de vous tenir informé des nouveautés du Coin du Prof.
                    Vous disposez d'un droit d'accès, de rectification ou de
                    suppression. Pour ce faire, merci de nous contacter via
                    l'adresse
                    <a href="mailto:contact@lecoinduprof.fr">
                        contact@lecoinduprof.fr</a>
                    et nous traiterons votre demande dans les meilleurs délais.
                </p>
                <p>
                    Conformément aux dispositions des Articles 6-III et 19 de la
                    Loi n°2004-575 du 21 juin 2004 pour la Confiance dans
                    l'économie numérique, dite L.C.E.N., il est porté à la
                    connaissance des Utilisateurs du site
                    <a href="https://www.lecoinduprof.fr/">
                        www.lecoinduprof.fr</a>
                    les présentes mentions légales. La connexion et la
                    navigation sur le site
                    <a href="https://www.lecoinduprof.fr/">www.lecoinduprof.fr</a>
                    par l'Utilisateur implique l'acceptation intégrale et sans
                    réserve des présentes mentions légales.
                </p>
                <p>
                    Ces dernières sont accessibles sur le site à la rubrique «
                    Mentions légales ».
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 1 : L'éditeur</p>
                <p>
                    L'édition du site
                    <a href="https://www.lecoinduprof.fr/">
                        www.lecoinduprof.fr</a>
                    est assurée par la Société COLOR THE EDUCATION au capital de
                    1000 euros, immatriculée au RCS de Bordeaux sous le numéro
                    901558189 00014 dont le siège social est situé au 3 rue Beaubadat 33000 Bordeaux.
                </p>
                <p>
                    Adresse e-mail :
                    <a href="mailto:contact@lecoinduprof.fr">contact@lecoinduprof.fr</a>
                </p>
                <p>N° de TVA intracommunautaire : FR08901558189</p>
                <p>Le Directeur de la publication est Mr Antoine PELTIER</p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 2 : L'hébergeur</p>
                <p>
                    L'hébergeur du site
                    <a href="https://www.lecoinduprof.fr/">www.lecoinduprof.fr</a>
                    est la Société OVH, dont le siège social est situé au 2 rue
                    Kellermann – 59100 Roubaix – France, avec le numéro de
                    téléphone : +33 9 72 10 10 07.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 3 : Accès au site</p>
                <p>
                    Le site est accessible par tout endroit, 7j/7, 24h/24 sauf
                    cas de force majeure, interruption programmée ou non et
                    pouvant découlant d'une nécessité de maintenance. En cas de
                    modification, interruption ou suspension des services le
                    site
                    <a href="https://www.lecoinduprof.fr/">www.lecoinduprof.fr</a>
                    ne saurait être tenu responsable.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 4 : Collecte des données</p>
                <p>
                    Le site assure à l'Utilisateur une collecte et un traitement
                    d'informations personnelles dans le respect de la vie privée
                    conformément à la loi n°78-17 du 6 janvier 1978 relative à
                    l'informatique, aux fichiers et aux libertés. En vertu de la
                    loi Informatique et Libertés, en date du 6 janvier 1978,
                    l'Utilisateur dispose d'un droit d'accès, de rectification,
                    de suppression et d'opposition de ses données personnelles.
                    L'Utilisateur exerce ce droit part mail à l'adresse
                    <a href="mailto:contact@lecoinduprof.fr">contact@lecoinduprof.fr</a>
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 5 : Cookies</p>
                <p>
                    L'Utilisateur est informé que lors de ses visites sur le
                    site, un cookie peut s'installer automatiquement sur son
                    logiciel de navigation. En naviguant sur le site, il les
                    accepte. Un cookie est un élément qui ne permet pas
                    d'identifier l'Utilisateur mais sert à enregistrer des
                    informations relatives à la navigation de celui-ci sur le
                    site Internet. L'Utilisateur pourra désactiver ce cookie par
                    l'intermédiaire des paramètres figurant au sein de son
                    logiciel de navigation.
                </p>
            </div>
            <div class="text-wrapper">
                <p class="subtitle">ARTICLE 6 : Propriété intellectuelle</p>
                <p>
                    Toute utilisation, reproduction, diffusion,
                    commercialisation, modification de toute ou partie du site
                    <a href="https://www.lecoinduprof.fr/">www.lecoinduprof.fr</a> sans autorisation de l'Editeur est
                    prohibée et pourra
                    entraînée des actions et poursuites judiciaires telles que
                    notamment prévues par le Code de la propriété intellectuelle
                    et le Code civil.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '../assets/scss/legal-notice.scss';
</style>
